import {
  creatorSchema,
  episodeSchema,
  podcastSchema,
  roleSchema,
  episodeCreditSchema,
  creditSubmissionSchema,
} from "api/schema/creator_podcast";
import { normalize, schema } from "normalizr";
import qs from "qs";
import { CANCEL } from "redux-saga";

import { aggregationSchema } from "../schema/aggregations";
import { brandSchema } from "../schema/brand";
import { networkAggregationTransformer } from "../transformer/networkAggregations";
import client from "./client";

export function loadSpecificPodcasts({ ids }) {
  return client.get(`podcasts?${qs.stringify({ ids })}`).then((response) => {
    const normalized = normalize(
      response.data,
      new schema.Array(podcastSchema)
    );
    return normalized;
  });
}

export function loadSpecificPodcast({ podcast_id }) {
  return client
    .get(`podcasts/${podcast_id}/`)
    .then((response) => normalize(response.data, podcastSchema));
}

export function loadEpisodesFilterList({
  sort_order,
  sort_direction,
  offset,
  count,
  filters,
  includes,
  suggestions,
  cancelToken,
  options,
  omit_results,
  total_hits,
}) {
  const request = client
    .post(
      "/list/episode",
      {
        start: offset,
        count,
        sort_order,
        sort_direction,
        filters,
        suggestions,
        includes,
        options,
        omit_results,
        total_hits,
      },
      { cancelToken: cancelToken.token }
    )
    .then((response) => {
      const { entities, additional_entities, ...data } = response.data;

      const normalised = normalize(
        { episodes: entities, ...additional_entities },
        {
          episodes: [episodeSchema],
          creators: [creatorSchema],
          roles: [roleSchema],
          podcasts: [podcastSchema],
          credits: [episodeCreditSchema],
          credit_submissions: [creditSubmissionSchema],
        }
      );

      const { result, ...rest } = normalised;

      return {
        result: result.episodes,
        ...rest,
        meta: { ...data },
      };
    });

  request[CANCEL] = () => cancelToken.cancel();

  return request;
}

export function loadPodcastsFilterList({
  sort_order,
  sort_direction,
  offset,
  count,
  filters,
  suggestions,
  rescore,
  cancelToken,
  options,
  omit_results,
  total_hits,
  aggregations,
}) {
  const request = client
    .post(
      "/list/podcast",
      {
        start: offset,
        count,
        sort_order,
        sort_direction,
        filters,
        suggestions,
        rescore,
        options,
        omit_results,
        total_hits,
        aggregations,
      },
      { cancelToken: cancelToken.token }
    )
    .then((response) => {
      const { entities, additional_entities, aggregations, ...data } =
        response.data;

      const normalised = normalize(
        { podcasts: entities, ...additional_entities, aggregations },
        {
          podcasts: [podcastSchema],
          creators: [creatorSchema],
          aggregations: [aggregationSchema],
        }
      );

      const { result, ...rest } = normalised;

      return {
        result: result.podcasts,
        ...rest,
        meta: { ...data },
      };
    });

  request[CANCEL] = () => cancelToken.cancel();

  return request;
}

export function loadNetworkCatalog({
  aggregations,
  count,
  filters,
  networkId,
  omit_results,
  sort_order,
  total_hits,
}) {
  const request = client
    .post("/list/podcast", {
      count,
      sort_order,
      filters,
      omit_results,
      total_hits,
      aggregations,
    })
    .then((response) => {
      const { entities, additional_entities, aggregations, ...data } =
        response.data;

      let aggregationsParsed = networkAggregationTransformer(aggregations);

      const normalised = normalize(
        { podcasts: entities, ...additional_entities },
        {
          podcasts: [podcastSchema],
          creators: [creatorSchema],
        }
      );

      const { result, ...rest } = normalised;

      return {
        result: result.podcasts,
        ...rest,
        networkId,
        networkAggregations: aggregationsParsed,
        meta: { ...data },
      };
    });

  return request;
}

export function loadSpecificEpisode({ episode_id }) {
  return client
    .get(`episodes/${episode_id}/`)
    .then((response) => normalize(response.data, episodeSchema));
}

export function saveEntity({ entity_id, entity_type }) {
  return client.post("my/saved", {
    entity_id,
    entity_type,
  });
}

export function unSaveEntity({ entity_id, entity_type }) {
  return client.delete(`my/saved/${entity_type}/${entity_id}`);
}

export function rateItem({ entity_type, entity_id, rating }) {
  return client.post(`${entity_type}s/${entity_id}/ratings`, {
    rating,
  });
}

export function deleteItemRating({ entity_type, entity_id }) {
  return client.delete(`${entity_type}s/${entity_id}/ratings`);
}

export function reviewItem({ entity_type, entity_id, review_text }) {
  return client.post(`${entity_type}s/${entity_id}/reviews`, {
    review_text,
  });
}

export function deleteReviewItem({ entity_type, entity_id }) {
  return client.delete(`${entity_type}s/${entity_id}/reviews`);
}

export function loadUserDataForEntities({ ids, cancelToken }) {
  const request = client.post("my/entity_data", { ids });

  request[CANCEL] = () => cancelToken.cancel();
  return request;
}

export function loadUserFavourites() {
  return client.get("my/podcasts/favourites").then((response) => {
    const normalized = normalize(
      response.data,
      new schema.Array(podcastSchema)
    );
    return normalized;
  });
}

export function followPodcast({ entity_id, following }) {
  return client.put(
    `/podcasts/${entity_id}/${following ? "follow" : "unfollow"}`
  );
}

export function refreshPodcastFeed({ podcast_id }) {
  return client.post(`/podcasts/${podcast_id}/refresh`);
}

export function addTrackedPodcast({ podcast_id }) {
  return client.put(`/podcasts/tracked/${podcast_id}`);
}

export function setCheckedEpisodeTracked({ episode_id }) {
  return client.put(`/episodes/tracked/${episode_id}`);
}

export function checkPodcastExternalIds({ podcast_id }) {
  return client.get(`/podcasts/${podcast_id}/player_ids`).then((response) => {
    const normalized = normalize(response.data, podcastSchema);
    return normalized;
  });
}

export function checkEpisodeExternalIds({ podcast_id, episode_id }) {
  return client
    .get(`/podcasts/${podcast_id}/episodes/${episode_id}/player_ids`)
    .then((response) => {
      const normalized = normalize(response.data, episodeSchema);
      return normalized;
    });
}

export function checkNewFeed({ podcast_id, new_feed }) {
  return client.get(
    `/podcasts/${podcast_id}/check_new_feed?${qs.stringify({ new_feed })}`
  );
}

export function confirmFeedChange({ podcast_id, new_feed }) {
  return client.put(
    `/podcasts/${podcast_id}/confirm_new_feed?${qs.stringify({ new_feed })}`
  );
}

export function getProDataForPodcast({ podcastId, forceProReload }) {
  return client
    .get(
      `/podcasts/${podcastId}/pro${forceProReload ? "?force-update-pro=1" : ""}`
    )
    .then((response) => {
      const { pro_data, additional_entities } = response.data;
      const normalized = normalize(
        {
          ...additional_entities,
          brands: pro_data?.sponsors,
        },
        {
          podcasts: [podcastSchema],
          brands: [brandSchema],
        }
      );

      return {
        entities: {
          pro_data: {
            [podcastId]: {
              ...pro_data,
              id: podcastId,
              brands: normalized?.result?.brands || [],
            },
          },
          ...normalized.entities,
        },
      };
    });
}

export function getProDataForEpisode({ episodeId }) {
  return client.get(`/episodes/${episodeId}/pro`).then((response) => {
    const { pro_data, additional_entities } = response.data;
    const normalized = normalize(
      {
        ...additional_entities,
        brands: pro_data?.episode_sponsors,
      },
      {
        podcasts: [podcastSchema],
        brands: [brandSchema],
      }
    );

    return {
      entities: {
        episode_pro_data: {
          [episodeId]: {
            ...pro_data,
            id: episodeId,
            brands: normalized?.result?.brands || [],
          },
        },
        ...normalized.entities,
      },
    };
  });
}

export function getTranscriptDataForEpisode({ episodeId }) {
  return client.get(`/episodes/${episodeId}/transcript`).then((response) => {
    return response?.data;
  });
}

export function loadListOfBrandsForPodcast({
  sort_order = "SORT_ORDER_RECENT",
  sort_direction = "desc",
  filters,
  offset,
  options,
  cancelToken,
  count,
  omit_results,
  total_hits,
}) {
  return client
    .post(
      "/list/brand",
      {
        start: offset,
        sort_order,
        sort_direction,
        filters,
        options,
        count,
        omit_results,
        total_hits,
      },
      { cancelToken: cancelToken.token }
    )
    .then((response) => {
      const { entities, additional_entities, ...data } = response.data;
      const normalised = normalize(
        {
          brands: entities,
          ...additional_entities,
        },
        {
          brands: [brandSchema],
        }
      );

      const { result, ...rest } = normalised;

      return {
        result: result.brands,
        ...rest,
        meta: { ...data },
      };
    });
}
export function loadListOfBrands() {
  return client.get("/brands").then((response) => {
    const { data, ...additional_entities } = response;
    const normalised = normalize(
      {
        brands: data,
        ...additional_entities,
      },
      {
        brands: [brandSchema],
      }
    );

    const { result, ...rest } = normalised;

    return {
      result: result.brands,
      ...rest,
    };
  });
}

export function saveProNote({ podcast_id, content }) {
  return client
    .post(`/podcasts/${podcast_id}/notes`, { content })
    .then((response) => response.data);
}

export function loadEpisodesOfEpisodeGroup({
  cancelToken,
  podcastId,
  year,
  sort_direction,
  filters,
}) {
  const request = client
    .post(`credit_editor/episodes/${podcastId}/year/${year}`, {
      sort_direction,
      filters,
      cancelToken: cancelToken.token,
    })
    .then((response) => {
      const data = response.data.map((elem) => ({
        ...elem,
        podcast_id: podcastId,
      }));

      const normalised = normalize(data, [episodeSchema]);

      const { result, ...rest } = normalised;

      return {
        result: result.episodes,
        ...rest,
      };
    });

  request[CANCEL] = () => cancelToken.cancel();

  return request;
}

//EpisodeTranscriptFlag
export function flagAircheck({ aircheckId }) {
  return client.post(`/flags/episode_sponsor/${aircheckId}`, {});
}

export function removeAircheckFlag({ aircheckId }) {
  return client.delete(`/flags/episode_sponsor/${aircheckId}`, {});
}

//ContactVotes
export function voteContact({ contactableId, direction, type }) {
  const body = {
    direction,
  };

  if (type) {
    body.type = type;
  }
  return client
    .post(`/vote/contactable/${contactableId}`, body)
    .then((response) => response.data);
}

export function cancelContactVote({ contactableId }) {
  return client
    .delete(`/vote/contactable/${contactableId}`, {})
    .then((response) => response.data);
}

//Flag Podcast Reach/ Demographics/ Socials
export function flagReachDemographicsOrSocial({ podcastId, flag_code }) {
  return client
    .post(`flags/podcast/${podcastId}`, { flag_code })
    .then((response) => response.data);
}

export function unflagReachDemographicsOrSocial({ podcastId, flag_code }) {
  return client
    .delete(`flags/podcast/${podcastId}?flag_code=${flag_code}`, {})
    .then((response) => response.data);
}

// Podcast Charts Data

export function loadPodcastRanksData({ podcastId }) {
  return client.post(`/podcasts/${podcastId}/ranks`).then((response) => {
    return response.data;
  });
}

export function loadPodcastChartsData({
  podcastId,
  platform,
  type,
  rankId,
  category,
  country,
}) {
  const filters = {
    chart: platform,
    category,
    country,
  };
  return client
    .post(`/podcasts/${podcastId}/charts`, { filters })
    .then((response) => {
      const { charts } = response.data;

      // Find the correct country data
      const countryData = charts[platform]?.countries.find(
        (country) => country.code === filters.country
      );

      if (!countryData) {
        throw new Error("Country data not found");
      }
      // Find the correct country or overall data. Takes care of either an array or obj
      let categoryData;
      if (Array.isArray(countryData[type])) {
        categoryData = countryData[type].find(
          (category) => category.title === filters["category"]
        );
      } else if (
        countryData[type] &&
        countryData[type].title === filters["category"]
      ) {
        categoryData = countryData[type];
      } else {
        throw new Error("Category data not found");
      }
      const rankings = categoryData && categoryData.rankings;

      return {
        rankings,
        podcastId,
        rankId,
      };
    });
}
